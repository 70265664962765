import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { axiosBridged } from "../../../utils/axiosBridged";
export default function AllVendors(props) {
  const trustId = useSelector(a => a.trust.id);
  const [data, setData] = useState(null);
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const fetchVendorDetails = async () => {
      try {
        console.log(window.location.href);
        const params = new URLSearchParams(window.location.search);

        const resp = await axiosBridged.get(
          `/trust/vendor-info?vendorId=${params.get("id")}`
        );
        // console.log(resp);
        setData(resp.data);
        setIsProcessing(false);
      } catch (error) {
        // setIsProcessing(false);
        console.log("error in fetching fetchVendorDetails-->", error);
      }
    };
    fetchVendorDetails();
  }, [trustId]);

  if (isProcessing) {
    return <p>loading...</p>;
  }

  return (
    <Container>
      <Row>
        <Col lg={12}>
          <div
            style={{
              paddingTop: 100
            }}
            className="text-center mb-4"
          >
            <Row>
              <Col lg={12}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>
                        {"Name of Applicant"}
                      </th>
                      <th style={{ textAlign: "center" }}>
                        {"Application ID"}
                      </th>

                      <th style={{ textAlign: "center" }}>{"Mobile Number"}</th>

                      <th style={{ textAlign: "center" }}>{"E-Mail ID"}</th>

                      <th style={{ textAlign: "center" }}>{"Balance"}</th>
                      <th style={{ textAlign: "center" }}>
                        {"Date of Acceptance"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{data.user.firstName}</td>
                      <td>{data.user.id}</td>

                      <td>{data.user.phone}</td>
                      <td>{data.user.email}</td>
                      <td>{data.user.freeBalance}</td>
                      <td>
                        {moment(data.user.createdAt)
                          .utc()
                          .format("DD/MM/YYYY")}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <div style={{ marginTop: 8 }}>
                <Col lg={12}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>
                          {"Transaction ID"}
                        </th>

                        <th style={{ textAlign: "center" }}>{"Amount"}</th>

                        <th style={{ textAlign: "center" }}>{"Date"}</th>
                        <th style={{ textAlign: "center" }}>{"Status"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.inrRequests.map(item => {
                        return (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.amount}</td>
                            <td>
                              {moment(item.createdAt)
                                .utc()
                                .format("DD/MM/YYYY")}
                            </td>
                            <td>{item.status}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </div>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
