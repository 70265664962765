import { applyMiddleware, createStore } from "redux";
import { logger } from "redux-logger";
import thunk from "redux-thunk";
import reducer from "./reducers";

const getMiddleWare = () => {
  return applyMiddleware(thunk, logger);
};

const store = createStore(reducer, getMiddleWare());

export default store;
