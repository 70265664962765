import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { axiosBridged } from "../../../utils/axiosBridged";
export default function DeclinedCC() {
  const trustId = useSelector(a => a.trust.id);
  const [data, setData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const fetchDeclinedCC = async () => {
      try {
        const resp = await axiosBridged.get(
          `/trust/cc-applications?trustId=${trustId}&status=DECLINED`
        );
        setData(resp.data);
        setIsProcessing(false);
      } catch (error) {
        setIsProcessing(false);
        console.log("error in fetching fetchDeclinedCC-->", error);
      }
    };
    fetchDeclinedCC();
  }, [trustId]);

  if (isProcessing) {
    return <p>loading...</p>;
  }

  return (
    <Container>
      <Row>
        <Col lg={12}>
          <div
            style={{
              paddingTop: 100
            }}
            className="text-center mb-4"
          >
            <Row>
              <Col lg={12}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>
                        {"Name of Applicant"}
                      </th>

                      <th style={{ textAlign: "center" }}>
                        {"Application ID"}
                      </th>
                      <th style={{ textAlign: "center" }}>
                        {"Date of Creation"}
                      </th>
                      <th style={{ textAlign: "center" }}>
                        {"Applicant phone"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>{item.firstName}</td>
                          <td>{item.id}</td>
                          <td>
                            {moment(item.createdAt)
                              .utc()
                              .format("DD/MM/YYYY")}
                          </td>
                          <td>{item.phone}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
