export const TRUST_ON_LOGIN = "TRUST_ON_LOGIN";
export const TRUST_CC_APPLICATIONS = "TRUST_CC_APPLICATIONS";
export const TRUST_SET_STATUS = "TRUST_SET_STATUS";
export const TRUST_GET_ALLSHREE = "TRUST_GET_ALLSHREE";
export const TRUST_RESETSTATE = "TRUST_RESETSTATE";
export const TRUST_SET_SHREE_STATUS = "TRUST_SET_SHREE_STATUS";
export const TRUST_GET_INRREQUESTS = "TRUST_GET_INRREQUESTS";
export const TRUST_GET_ALLPROJECTS = "TRUST_GET_ALLPROJECTS";
export const TRUST_SET_PROJ_STATUS = "TRUST_SET_PROJ_STATUS";
export const TRUST_SET_INR_STATUS = "TRUST_SET_INR_STATUS";
export const TRUST_GET_ALLVENDOR = "TRUST_GET_ALLVENDOR";
export const TRUST_SET_VENDOR_STATUS = "TRUST_SET_VENDOR_STATUS";
export const TRUST_GET_CC_APPROVED = "TRUST_GET_CC_APPROVED";
export const TRUST_GET_CC_INFO = "TRUST_GET_CC_INFO";
export const TRUST_GET_VENDOR_APPROVED = "TRUST_GET_VENDOR_APPROVED";
export const TRUST_GET_VENDOR_INFO = "TRUST_GET_VENDOR_INFO";
export const TRUST_GET_PROJECT_APPROVED = "TRUST_GET_PROJECT_APPROVED";
export const TRUST_GET_PROJECT_INFO = "TRUST_GET_PROJECT_INFO";
export const RESET = "RESET";
