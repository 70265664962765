import QRCode from "qrcode.react";
import React, { useState } from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  Container,
  Modal,
  Row,
  Table
} from "react-bootstrap";
import PositiveButton from "../../components/PositiveButton";
import ReactToPrint from "react-to-print";
import { TEXTBLACK } from "../../res/colors";
import { mediumTextStyle } from "../../res/styles";
import { axiosBridged } from "../../utils/axiosBridged";

let idCard = null;

export default function Search() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [userQRData, setUserQRData] = useState(null);
  const [currentUser, setCurrentUser] = useState();
  const onSearchPress = async () => {
    try {
      const resp = await axiosBridged.get(
        `/trust/search/comsumer?searchTerm=${searchTerm}&offset=${0}&limit=${50}`
      );
      setSearchData(resp.data);
    } catch (error) {
      console.log("error in fetching onSearchPress-->", error);
    }
  };

  const fetchQR = async (currentQRUser, userId) => {
    try {
      let resp = await axiosBridged.get(`/trust/get-qrdata?userId=${userId}`);
      setUserQRData(resp.data);
      setCurrentUser(currentQRUser);
    } catch (error) {
      console.log("error in fetching fetchQR-->", error);
    }
  };

  const showPlayersBalance = async (firstName, lastName, id) => {
    try {
      let resp = await axiosBridged.get(
        `/trust/get-player-balances?userId=${id}`
      );
      alert(`${
        firstName.trim() === lastName.trim()
          ? firstName
          : firstName + " " + lastName
      }'s balances.
      Free Balance : ${resp.data.freeBalance || 0}
      HybernatedBalance : ${resp.data.hybernatedBalance || 0}`);
    } catch (err) {
      console.log("error in fetching balance");
    }
  };

  const promoteToVendor = async userId => {
    try {
      await axiosBridged.post("/trust/make-vendor", {
        consumerId: userId
      });
      onSearchPress();
    } catch (e) {
      console.log("error while promoting", e);
    }
  };

  const handleChange = async event => {
    setSearchTerm(event.target.value);
    onSearchPress();
  };

  return (
    <div>
      <Container>
        <form className="form-signin">
          <Row>
            <Col lg={12}>
              <div
                style={{
                  paddingTop: 100
                }}
                className="text-center mb-4"
              >
                <Row>
                  <Col lg={10}>
                    <FormGroup controlId="groupname">
                      <FormControl
                        name="search"
                        type="text"
                        value={searchTerm}
                        placeholder="Search by name or phone"
                        onChange={handleChange}
                        style={{
                          width: "88%",
                          color: TEXTBLACK,
                          ...mediumTextStyle
                        }}
                      />
                      <FormControl.Feedback />
                    </FormGroup>
                  </Col>
                  <Col lg={2}>
                    <PositiveButton
                      text="SEARCH"
                      action={onSearchPress}
                      style={{
                        width: "100%"
                      }}
                    />
                  </Col>
                </Row>

                <Col lg={12}>
                  <div
                    style={{
                      padding: 10,
                      paddingTop: 20
                    }}
                  >
                    {searchData.length > 0 ? (
                      <Table responsive striped bordered condensed hover>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>aadhar</th>
                            <th>type</th>
                            <th>balance</th>
                            <th>QR</th>
                          </tr>
                        </thead>
                        <tbody>
                          {searchData.map(item => {
                            return (
                              <tr key={item.id}>
                                <td>
                                  {item.firstName === item.lastName
                                    ? item.firstName
                                    : item.firstName + " " + item.lastName}
                                </td>
                                <td>{item.phone}</td>
                                <td>
                                  {item.aadharNumber
                                    ? item.aadharNumber
                                    : "---"}
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      promoteToVendor(item.id);
                                    }}
                                    disabled={item.type !== "CONSUMER"}
                                  >
                                    {item.type !== "CONSUMER"
                                      ? item.type
                                      : "PROMOTE TO VENDOR"}
                                  </Button>
                                </td>

                                <td>
                                  <Button
                                    onClick={() => {
                                      showPlayersBalance(
                                        item.firstName,
                                        item.lastName,
                                        item.id
                                      );
                                    }}
                                  >
                                    Get balance
                                  </Button>
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      fetchQR(
                                        item.firstName === item.lastName
                                          ? item.firstName
                                          : item.firstName +
                                              " " +
                                              item.lastName,
                                        item.id
                                      );
                                    }}
                                  >
                                    Get QR
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    ) : (
                      <h5>Results are displayed here</h5>
                    )}
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
      <Modal show={userQRData !== null} onHide={() => setUserQRData(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{`Id for the user ${currentUser}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            ref={el => (idCard = el)}
            style={{ textAlign: "center", marginTop: 24 }}
          >
            <QRCode value={userQRData || "0"} />
            <h2>{currentUser}</h2>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ReactToPrint
            trigger={() => <Button>PRINT</Button>}
            content={() => idCard}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}
