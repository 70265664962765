import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { checkIsLoggedIn } from "./redux/actions/trustActions";
import Home from "./views/Home";
import Login from "./views/Login";
import LogOut from "./views/LogOut";
import CCApplications from "./views/CC/CCApplications";
import AllCC from "./views/CC/AllCC";
import AcceptedCC from "./views/CC/AcceptedCC";
import DeclinedCC from "./views/CC/DeclinedCC";
import CCDetails from "./views/CC/CCDetails";
import VendorINRRequests from "./views/Vendor/VendorINRRequests";
import AllVendors from "./views/Vendor/AllVendors";
import VendorDetails from "./views/Vendor/VendorDetails";
import PendingProjects from "./views/Projects/PendingProjects";
import DeclinedProjects from "./views/Projects/DeclinedProjects";
import AcceptedProjects from "./views/Projects/AcceptedProjects";
import ProjectDetails from "./views/Projects/ProjectDetails";
import AllProjects from "./views/Projects/AllProjects";
import Search from "./views/Search";
import ProjectINRRequests from "./views/Projects/ProjectINRRequests";
class Routes extends Component {
  async componentDidMount() {
    try {
      await this.props.checkIsLoggedIn();
    } catch (error) {
      console.log("error-->??", error);
    }
  }

  render() {
    return (
      <>
        <Switch>
          {/*  <Redirect exact from="/" to="/home" /> */}
          <Route component={Home} exact path="/" />
          <Route component={Home} exact path="/home" />
          <Route component={Login} exact path="/login" />
          <Route component={LogOut} exact path="/logout" />
          <Route component={Search} exact path="/search" />

          <Route component={CCApplications} exact path="/ccApplications" />
          <Route component={CCApplications} exact path="/ccShreeRequests" />
          <Route component={AllCC} exact path="/allcc" />
          <Route component={AcceptedCC} exact path="/acceptedCC" />
          <Route component={DeclinedCC} exact path="/declinedCC" />
          <Route component={CCDetails} exact path="/ccDetails" />

          <Route
            component={PendingProjects}
            exact
            path="/projectPendingApplications"
          />

          <Route
            component={DeclinedProjects}
            exact
            path="/projectDeclinedApplications"
          />
          <Route
            component={AcceptedProjects}
            exact
            path="/projectAcceptedApplications"
          />
          <Route
            component={ProjectINRRequests}
            exact
            path="/projectINRRequests"
          />
          <Route component={AllProjects} exact path="/allProjects" />

          <Route component={ProjectDetails} exact path="/projectDetails" />

          <Route
            component={VendorINRRequests}
            exact
            path="/vendorInrRequests"
          />
          <Route component={AllVendors} exact path="/allVendors" />
          <Route component={VendorDetails} exact path="/vendorDetails" />

          <Redirect to="/not-found" />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { checkIsLoggedIn }
)(Routes);
