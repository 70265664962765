import * as cons from "../constants/trustConstants";

const initialState = {
  id: "",
  firstName: "",
  lastName: "",
  isLoggedIn: false,
  ccApplications: [],
  allShree: [],
  allProjects: [],
  inrRequests: [],
  allVendors: [],
  allCCApproved: [],
  getCCInfo: {},
  allApprovedVendors: [],
  allVendorInfo: null,
  allApprovedProjects: [],
  allProjectInfo: {}
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case cons.TRUST_ON_LOGIN: {
      state = {
        isLoggedIn: action.payload.isLoggedIn,
        ...action.payload.user
      };
      break;
    }
    case cons.TRUST_CC_APPLICATIONS: {
      // console.log("CC_APPLICATIONS", action.payload);
      state = {
        ...state,
        ccApplications: action.payload
      };
      break;
    }
    case cons.TRUST_GET_ALLSHREE: {
      // console.log("get all", action.payload);
      state = {
        ...state,
        allShree: action.payload
      };
      break;
    }
    case cons.TRUST_GET_INRREQUESTS: {
      // console.log("get all", action.payload);
      state = {
        ...state,
        inrRequests: action.payload
      };
      break;
    }
    case cons.TRUST_GET_ALLPROJECTS: {
      // console.log("get all", action.payload);
      state = {
        ...state,
        allProjects: action.payload
      };
      break;
    }
    case cons.TRUST_GET_CC_APPROVED: {
      // console.log("get cc info", action.payload);
      state = {
        ...state,
        allCCApproved: action.payload
      };
      break;
    }
    case cons.TRUST_GET_CC_INFO: {
      // console.log("get cc info", action.payload);
      state = {
        ...state,
        getCCInfo: action.payload
      };
      break;
    }
    case cons.TRUST_GET_PROJECT_APPROVED: {
      // console.log("get all", action.payload);
      state = {
        ...state,
        allApprovedProjects: action.payload
      };
      break;
    }
    case cons.TRUST_GET_PROJECT_INFO: {
      //console.log("get all vendor info", action.payload);
      state = {
        ...state,
        allProjectInfo: action.payload
      };
      break;
    }
    case cons.TRUST_GET_VENDOR_APPROVED: {
      // console.log("get all", action.payload);
      state = {
        ...state,
        allApprovedVendors: action.payload
      };
      break;
    }
    case cons.TRUST_GET_VENDOR_INFO: {
      console.log("get all vendor info", action.payload);
      state = {
        ...state,
        allVendorInfo: action.payload
      };
      break;
    }
    case cons.TRUST_GET_ALLVENDOR: {
      // console.log("get all", action.payload);
      state = {
        ...state,
        allVendors: action.payload
      };
      break;
    }
    case cons.TRUST_RESETSTATE: {
      // console.log("get all", action.payload);
      state = initialState;
      break;
    }
    case cons.RESET: {
      state = initialState;
      break;
    }
    default: {
      return state;
    }
  }

  return state;
};

export default user;
