import axios from "axios";
import axiosRetry from "axios-retry";
import { Service } from "axios-middleware";
export const axiosBridged = axios.create({
  baseURL: "https://shreecurrency.com/api",
  timeout: 10000,
  withCredentials: true
});

const service = new Service(axiosBridged);

service.register({
  // onRequest(config) {
  //   return { ...config, headers: { authToken: LOGIN_TOKEN } };
  // },
  // onSync(promise) {
  //   console.log('onSync');
  //   return promise;
  // },
  onRequestError(error) {
    console.log("go to not internet page", error);
  },

  onResponse(response) {
    // console.log("onResponse", response);
    return JSON.parse(response.data);
  },

  onResponseError(error) {
    console.log("response error", error, error.response);
    alert(JSON.parse(error.response.data).message);
    throw new Error(JSON.parse(error.response.data).message);
  }
});

axiosRetry(axiosBridged, { retries: 3 });
