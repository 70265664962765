import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../../redux/actions/trustActions";

class LogOut extends Component {
  componentDidMount() {
    // console.log("props-->", this.props);
    this.props.logout();
  }
  render() {
    return (
      <div>
        <p>loading...</p>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { logout }
)(LogOut);
