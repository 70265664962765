import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import PositiveButton from "../../../components/PositiveButton";
import { axiosBridged } from "../../../utils/axiosBridged";

export default function VendorINRRequests() {
  const trustId = useSelector(a => a.trust.id);
  const [data, setData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const fetchINRRequests = async () => {
      try {
        const resp = await axiosBridged.get(
          `/trust/inr-requests?trustId=${trustId}&type=VENDOR`
        );
        setData(resp.data);
        setIsProcessing(false);
      } catch (error) {
        setIsProcessing(false);
        console.log("error in fetching fetchINRRequests-->", error);
      }
    };
    fetchINRRequests();
  }, [trustId]);

  if (isProcessing) {
    return <p>loading...</p>;
  }

  const setVendorStatus = async (status, requesterId, requestId) => {
    try {
      setIsProcessing(true);
      await axiosBridged.post("/trust/inr-requests/", {
        status: status,
        requesterId: requesterId,
        requestId: requestId,
        trustId: trustId
      });
      const resp = await axiosBridged.get(
        `/trust/inr-requests?trustId=${trustId}&type=VENDOR`
      );
      setData(resp.data);
      setIsProcessing(false);
    } catch (err) {
      setIsProcessing(false);
    }
  };

  return (
    <Container>
      <Row>
        <Col lg={12}>
          <div
            style={{
              paddingTop: 100
            }}
            className="text-center mb-4"
          >
            <h2>INR REQUESTS</h2>
            <Row>
              <Col lg={12}>
                <Table striped bordered condensed hover>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>
                        {"Name of Applicant"}
                      </th>
                      <th style={{ textAlign: "center" }}>{"Request ID"}</th>
                      <th style={{ textAlign: "center" }}>{"amount"}</th>
                      <th style={{ textAlign: "center" }}>{"creation Date"}</th>
                      <th style={{ textAlign: "center" }}>{"More"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>{item.requesterName}</td>
                          <td>{item.id}</td>
                          <td>{item.amount}</td>

                          <td>
                            {moment(item.createdAt)
                              .utc()
                              .format("DD/MM/YYYY")}
                          </td>

                          <td>
                            <PositiveButton
                              text={"ACCEPT"}
                              style={{ width: "30%", margin: 8 }}
                              disabled={isProcessing}
                              action={() => {
                                setVendorStatus(
                                  "APPROVED",
                                  item.requesterId,
                                  item.id
                                );
                              }}
                            />
                            <PositiveButton
                              text={"DECLINE"}
                              disabled={isProcessing}
                              style={{ width: "30%", margin: 8 }}
                              action={() => {
                                setVendorStatus(
                                  "DECLINED",
                                  item.requesterId,
                                  item.id
                                );
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
