// import { StyleSheet, Platform } from 'react-native';
import { TEXTBLACK } from "./colors";

export const _textStyle = {
  fontFamily: "OpenSans-SemiBold"
};

export const _boldTextStyle = {
  fontFamily: "OpenSans-Bold"
};

export const FLEX_CENTERED = {
  display: "flex",
  flex: 1,
  justifyContent: "center",
  alignItems: "center"
};

//All generic text Styles

export const _8pxLightTextStyle = {
  fontFamily: " 'Open Sans', sans-serif",
  fontWeight: "300",
  fontSize: 9
};
export const mediumLightTextStyle = {
  fontFamily: "Open Sans, sans-serif",
  fontWeight: "300",
  fontSize: 12
};
export const largeLightTextStyle = {
  fontFamily: "Open Sans, sans-serif",
  fontWeight: "300",
  fontSize: 16
};

export const _32pxLightTextStyle = {
  fontFamily: "Open Sans, sans-serif",
  fontWeight: "300",
  fontSize: 32
};

export const _8pxTextStyle = {
  fontFamily: " 'Open Sans', sans-serif",
  fontWeight: "400",
  fontSize: 9
};
export const mediumTextStyle = {
  fontFamily: "Open Sans, sans-serif",
  fontWeight: "400",
  fontSize: 12
};
export const largeTextStyle = {
  fontFamily: "Open Sans, sans-serif",
  fontWeight: "400",
  fontSize: 16
};

export const _8pxSemiBoldTextStyle = {
  fontFamily: " 'Open Sans', sans-serif",
  fontWeight: "600",
  fontSize: 9
};
export const mediumSemiBoldTextStyle = {
  fontFamily: " 'Open Sans', sans-serif",
  fontWeight: "600",
  fontSize: 12
};
export const largeSemiBoldTextStyle = {
  fontFamily: " 'Open Sans', sans-serif",
  fontWeight: "600",
  fontSize: 16
};

export const xLargeSemiBoldTextStyle = {
  fontFamily: " 'Open Sans', sans-serif",
  fontWeight: "600",
  fontSize: 24
};

export const _8pxBoldTextStyle = {
  fontFamily: " 'Open Sans', sans-serif",
  fontWeight: "700",
  fontSize: 9
};
export const mediumBoldTextStyle = {
  fontFamily: " 'Open Sans', sans-serif",
  fontWeight: "700",
  fontSize: 12
};
export const largeBoldTextStyle = {
  fontFamily: " 'Open Sans', sans-serif",
  fontWeight: "700",
  fontSize: 16
};

export const BOLDTEXTSTYLE = {
  fontFamily: " 'Open Sans', sans-serif",
  fontWeight: "700",
  color: TEXTBLACK
};

export const SEMIBOLDTEXTSTYLE = {
  fontFamily: " 'Open Sans', sans-serif",
  fontWeight: "600",
  color: TEXTBLACK
};

export const REGULARTEXTSTYLE = {
  fontFamily: " 'Open Sans', sans-serif",
  fontWeight: "400",
  color: TEXTBLACK
};

export const _screenStyle = {
  flex: 1,
  backgroundColor: "#ffffff"
};

export const _16DpStyle = {
  width: 16,
  height: 16
};

export const _24DpStyle = {
  width: 24,
  height: 24
};

export const _40DpStyle = {
  width: 40,
  height: 40
};

export const _60DpStyle = {
  width: 60,
  height: 60
};

export const centerAlignEverything = {
  textAlign: "center",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  alignSelf: "center"
};

export const BASEFONT = 16;
export const REGULARFONT = 12;
export const MEDIUMFONT = 10;
export const SMALLFONT = 8;
export const BASE = 16;
