import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { axiosBridged } from "../../utils/axiosBridged";
import { Col, Container, Row, Table } from "react-bootstrap";

const TransactionGroups = ({ tGroups }) => (
  <Table responsive striped bordered hover>
    <thead>
      <tr>
        <th>Type</th>
        <th>Sum</th>
      </tr>
    </thead>
    <tbody>
      {tGroups.map(a => (
        <tr key={a.type}>
          <td>{a.type}</td>
          <td>{a.total}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default function Home() {
  const trustId = useSelector(a => a.trust.id);
  const [data, setData] = useState(null);
  const [transactionGroupData, setTransactionGroupData] = useState(null);

  useEffect(() => {
    if (trustId === undefined) {
      return;
    }
    const fetchData = async () => {
      try {
        let resp = await axiosBridged.get(`/trust/summary?trustId=${trustId}`);
        let resp2 = await axiosBridged.get(
          `/trust/get-transaction-groups?trustId=${trustId}`
        );
        setTransactionGroupData(resp2.data);
        setData(resp.data);
      } catch (error) {
        console.log("error in fetching fetchData-->", error);
      }
    };
    fetchData(trustId);
  }, [trustId]);

  return (
    <div style={{ marginTop: 50 }}>
      <Container>
        {data !== null ? (
          <Row>
            <Col lg={12}>
              <h4>Stats</h4>
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Type (cumilative balance)</th>
                    <th>NOs</th>
                    <th>Total Shree</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>CURRENCY CREATORS</td>
                    <td>{data.cc.ccCount || 0}</td>
                    <td>{data.cc.ccShree || 0}</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>PROJECTS</td>
                    <td>{data.project.projectCount || 0}</td>
                    <td>{data.project.projectShree || 0}</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>VENDORS</td>
                    <td>{data.vendor.vendorCount || 0}</td>
                    <td>{data.vendor.vendorShree || 0}</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>CONSUMERS</td>
                    <td>{data.consumer.consumerCount || 0}</td>
                    <td>{data.consumer.consumerShree || 0}</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>IN HYBERNATION</td>
                    <td>{"-" || 0}</td>
                    <td>{data.hybernated.hybernatedShree || 0}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        ) : (
          <h4>Loading...</h4>
        )}

        <Row>
          <Col lg={12}>
            <h4>Running Transactions</h4>
            {transactionGroupData !== null ? (
              <TransactionGroups tGroups={transactionGroupData} />
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
