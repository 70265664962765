import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { login } from "../../redux/actions/trustActions";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isProcessing: false,
      validated: false
    };
  }

  componentDidMount() {
    console.log(this.props);
  }

  onLogin = async () => {
    try {
      const { email, password } = this.state;
      await this.props.login({ email, password });
    } catch (error) {
      console.log("error in fetching onLogin-->", error);
    }
  };

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ ...this.state, [name]: value });
  };

  handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      this.setState({ ...this.state, validated: true });
      this.props.login({
        email: this.state.email,
        password: this.state.password
      });
    } else {
      this.setState({ ...this.state, validated: true });
    }
  };

  render() {
    return (
      <Container>
        <Row>
          <Col lg={{ span: 4, offset: 4 }} style={{ marginTop: 32 }}>
            <Form
              noValidate
              validated={this.state.validated}
              onSubmit={this.handleSubmit}
            >
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  onChange={this.onChange}
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  required
                  name="password"
                  onChange={this.onChange}
                />
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                // disabled={!this.state.validated}
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { login }
)(Login);
