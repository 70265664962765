import * as cons from "../constants/trustConstants";
import { axiosBridged } from "../../utils/axiosBridged";

export const login = data => async dispatch => {
  try {
    const resp = await axiosBridged.post("/trust/web/login", data);

    dispatch({
      type: cons.TRUST_ON_LOGIN,
      payload: {
        isLoggedIn: resp.data.isLoggedIn,
        ...resp.data.user
      }
    });

    window.location.replace("/");
  } catch (err) {
    console.log(err);
  }
};

export const checkIsLoggedIn = () => async dispatch => {
  try {
    const resp = await axiosBridged.post("/trust/web/login", {});
    dispatch({
      type: cons.TRUST_ON_LOGIN,
      payload: resp.data
    });
  } catch (err) {
    console.log(window.location.pathname);
    if (window.location.pathname !== "/login") {
      window.location.replace("/login");
    }
    console.log("login error", err);
  }
};

export const logout = () => async dispatch => {
  try {
    await axiosBridged.get("/trust/web/logout");
    dispatch({
      type: cons.TRUST_RESETSTATE,
      payload: {}
    });
    window.location.reload();
  } catch (err) {
    alert("An error occured");
  }
};
