import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import PositiveButton from "../../../components/PositiveButton";
import { axiosBridged } from "../../../utils/axiosBridged";
export default function AllVendors() {
  const [data, setData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const fetchAllVendors = async () => {
      try {
        const resp = await axiosBridged.get("/trust/vendors");
        setData(resp.data);
        setIsProcessing(false);
      } catch (error) {
        setIsProcessing(false);
        console.log("error in fetching fetchAllVendors-->", error);
      }
    };
    fetchAllVendors();
  }, []);

  const expandDetails = id => {
    window.location.replace(`/vendorDetails?id=${id}`);
  };

  if (isProcessing) {
    return <p>loading...</p>;
  }

  return (
    <Container>
      <Row>
        <Col lg={12}>
          <div
            style={{
              paddingTop: 100
            }}
            className="text-center mb-4"
          >
            <Col lg={12}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>{"id"}</th>
                    <th style={{ textAlign: "center" }}>{"Name Of CC"}</th>

                    <th style={{ textAlign: "center" }}>
                      {"Currency Creator ID"}
                    </th>

                    <th style={{ textAlign: "center" }}>{"Mobile Number "}</th>
                    <th style={{ textAlign: "center" }}>
                      {"Date Of Acceptance"}
                    </th>
                    <th style={{ textAlign: "center" }}>{"OPS"}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(item => {
                    return (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.firstName}</td>
                        <td>{item.phone}</td>
                        <td>{item.phone}</td>
                        <td>
                          {moment(item.updatedAt)
                            .utc()
                            .format("DD/MM/YYYY")}
                        </td>
                        <td>
                          <PositiveButton
                            text={"DETAILS"}
                            action={() => {
                              expandDetails(item.id);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
