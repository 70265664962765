export const PRIMARY = "#212121";
export const LIGHT = "#ffffff";
export const GOLD = "#b69751";
export const DARK = "#000000";
export const GRAY = "#9d9d9d";
export const GREEN = "#009688";
export const HAZYBLUE = "#F2F5FC";
export const NONVEGBROWN = "#843821";
export const TEXTBLACK = "#333333";
export const RED = "#F44336";
export const BORDER = "#CFD8DC";
export const GREYBLUE = "#999999";
export const DISABLEDGREY = "#cccccc";
export const BUTTONORANGE = "#E67E22";
export const LIKEBLUE = "#90A4AE";
export const BORDERCOLOR = "#777777";
export const HAZYRED = "#FF6A6A";
export const COMPLETDBLUE = "#2980B9";
