import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import PositiveButton from "../../../components/PositiveButton";
import { axiosBridged } from "../../../utils/axiosBridged";
export default function PendingProjects() {
  const trustId = useSelector(a => a.trust.id);
  const [data, setData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const fetchProjectApplications = async () => {
      try {
        const resp = await axiosBridged.get(
          `/trust/project-applications?status=PENDING`
        );
        setData(resp.data);
        setIsProcessing(false);
      } catch (error) {
        setIsProcessing(false);
        console.log("error in fetching fetchProjectApplications-->", error);
      }
    };
    fetchProjectApplications();
  }, [trustId]);

  const setProjectApplicationStatus = async (status, id) => {
    try {
      setIsProcessing(true);
      await axiosBridged.post("/trust/project-applications", {
        projectId: id,
        status: status
      });
      const resp = await axiosBridged.get(
        `/trust/project-applications?status=PENDING`
      );
      setIsProcessing(false);
      setData(resp.data);
    } catch (err) {
      setIsProcessing(false);
      console.log(err);
    }
  };

  if (isProcessing) {
    return <p>loading...</p>;
  }

  return (
    <Container>
      <form className="form-signin">
        <Row>
          <Col lg={12}>
            <div
              style={{
                paddingTop: 100
              }}
              className="text-center mb-4"
            >
              <Row>
                <Col lg={12}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>
                          {"Name of Applicant"}
                        </th>

                        <th style={{ textAlign: "center" }}>
                          {"Application ID"}
                        </th>

                        <th style={{ textAlign: "center" }}>
                          {"Applicant phone"}
                        </th>

                        <th style={{ textAlign: "center" }}>
                          {"Date of Creation"}
                        </th>
                        <th style={{ textAlign: "center" }}>
                          {"More Details"}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map(item => {
                        return (
                          <tr key={item.id}>
                            <td>{item.firstName}</td>
                            <td>{item.id}</td>
                            <td>
                              {moment(item.createdAt)
                                .utc()
                                .format("DD/MM/YYYY")}
                            </td>
                            <td>{item.phone}</td>
                            <td>
                              <PositiveButton
                                text={"ACCEPT"}
                                style={{ width: "30%", margin: 8 }}
                                action={() => {
                                  setProjectApplicationStatus(
                                    "APPROVED",
                                    item.id
                                  );
                                }}
                              />
                              <PositiveButton
                                text={"DECLINE"}
                                style={{ width: "30%", margin: 8 }}
                                action={() => {
                                  setProjectApplicationStatus(
                                    "DECLINED",
                                    item.id
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
