import React, { Component } from "react";
import "./App.css";
import Header from "./components/Header";
import Routes from "./Routes";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

import { connect } from "react-redux";

const browserHistory = createBrowserHistory();
//dev init
class App extends Component {
  render() {
    return (
      <div>
        <Header userId={this.props.userId} />
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userId: state.trust.id
});

export default connect(
  mapStateToProps,
  {}
)(App);
