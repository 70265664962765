import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
export default function Header(props) {
  return (
    <>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/..">Shree Trust</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {props.userId !== "" ? (
              <>
                <Nav.Link href="/home">Home</Nav.Link>
                <NavDropdown title="Currency Creator" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/ccApplications">
                    Pending Applications
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/ccShreeRequests">
                    Shree Requests
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/allcc">
                    All Currency Creators
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/acceptedCC">
                    Accepted Applications
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/declinedCC">
                    Declined Applications
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Vendor" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/vendorInrRequests">
                    INR Requests
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/allVendors">
                    ALL Existing Vendors
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Projects" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/projectPendingApplications">
                    Pending Applications
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/projectDeclinedApplications">
                    Declined Applications
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/projectAcceptedApplications">
                    Accepted Applications
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/projectINRRequests">
                    Project INR Requests
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/allProjects">
                    All Projects
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/search">Search</Nav.Link>
                <Nav.Link href="/logout">Log Out</Nav.Link>
              </>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
