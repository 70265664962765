import React from "react";
import { Button } from "react-bootstrap";
import { mediumBoldTextStyle } from "../res/styles";

const PositiveButton = props => (
  <Button
    // bsStyle="success"
    // type="submit"
    onClick={props.action}
    lable={props.lable}
    lable2={props.lable2}
    disabled={props.disabled}
    style={{
      width: 160,
      height: 40,
      borderRadius: 0,
      marginLeft: props.marginLeft,
      ...mediumBoldTextStyle,
      ...props.style
    }}
  >
    {props.text || "ACCEPT"}
  </Button>
);
export default PositiveButton;
